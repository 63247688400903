
.button {
  background: white;
  margin: 1rem 0;
  padding: 0.6rem 5rem;
  border: none;
  border-radius: 2rem;
  cursor: pointer;
  color: black;
  position: relative;
  overflow: hidden;
  border-radius: 5rem;
}

.button span {
  transition: all 0.3s ease;
  overflow: hidden;
  white-space: nowrap;
  font-size: 1.25rem;
  z-index: 10;
  width: 0;
  margin-left: 0;
}
.button:hover span {
  width: auto;
  margin-left: 0.75rem;
}

