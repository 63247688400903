#works {
    margin: 0 auto;
    min-height: calc(100vh - 4rem);
    width: 100vw;
    max-width: 90rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 3rem;
}
p{
  color: white;
}

.worksTitle {
    margin: 1rem 0;
    font-size: 3rem;
}

.worksDesc {
    font-weight: 300;
    font-size: 1rem;
    max-width: 45rem;
}

.worksImgs {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100vw;
    gap: 1rem;
    max-width: 50rem;
    margin-top: 3rem;
}

.works-img{
  width: 15rem;
  height: 15rem;
  border-radius: 10%;
  border: 2px solid transparent;
  object-fit: cover;
}

li{
  list-style: none;
}

.workBtn {
    margin: 3rem 0;
    padding: 0.5rem 2.5rem;
    border: none;
    border-radius: 2rem;
    background: white;
    font-size: 1rem;
    cursor: pointer;
    margin-bottom: 6rem;
    font-weight: bold;
    color: black;
}

@media screen and (max-width:585px) {
    .works-img{
       width: 150px;
       height: 150px;
    }
}