.footer {
    text-align: center;
    width: 100vw;
    height: 10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 300;
    font-size: small;
    background-color: #282c34;
    color: white;
    padding: 1rem;
    box-sizing: border-box;
  }
  
  .links {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }
  
  .link {
    height: 3rem;
    width: 3rem;
    margin: 0 0.75rem;
    transition: transform 0.3s;
  }
  
  .link:hover {
    transform: scale(1.1);
  }
  