.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: -30rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-content {
    width: 24rem;
    max-width: 36rem;
    background: rgba(30,30,30);
    padding: 20px;
    border: 0.5rem solid #0056b3;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    color: white;
  }
  
  .popup-content h2 {
    margin-top: 0;
    font-size: 1.3rem;
  }
  
  .popup-content button {
    margin-top: 1.5rem;
    padding: 10px 20px;
    background: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .popup-content button:hover {
    background: #0056b3;
  }
  
  @media screen and (max-width:685px) {
    .popup-content{
        width: 100%;
        max-width: 22rem;
    }
}

@keyframes slide-up-fade-in {
  0%{
    transform: translateY(30px);
    opacity: 0;
  }

  100%{
    transform: translateY(0);
    opacity: 1;
  }
}
