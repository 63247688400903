#intro {
  width: 100vw;
  max-width: 100%;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  padding: 3rem;
  background-color: #282c34;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.2s ease-in-out;
}

#intro:hover {
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.3);
}

.introContent {
  font-size: 3rem;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hello {
  font-size: 1.75rem;
  font-weight: 100;
}

@keyframes bounceText {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.hello {
  animation: bounceText 1.5s ease-in-out infinite;
}

.introName {
  animation: bounceText 1.5s ease-in-out infinite;
}

@keyframes typing {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.introText {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  width: 0;
  animation: typing 3s steps(30) 1s forwards;
}

.introName {
  color: yellow;
}

.introPara {
  font-size: medium;
  font-weight: 300;
  letter-spacing: 1px;
}

.button {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.button span {
  position: relative;
  z-index: 1;
}

.button::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300%;
  height: 300%;
  background: #687488;
  transition: all 0.4s;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  z-index: 0;
  border: none;
}

.button:hover::after {
  transform: translate(-50%, -50%) scale(1);
}

.button:hover span {
  color: white;
}

@media screen and (max-width: 600px) {
  .introContent {
    margin-top: 3rem;
    font-size: 10vw;
  }

  #intro {
    flex-direction: column;
  }
}

@media screen and (max-width: 1200px) {
  #intro {
    padding: 1.5rem;
  }
}

@media screen and (max-width: 992px) {
  #intro {
    padding: 1rem;
    flex-direction: column;
  }

  .introContent {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 768px) {
  #intro {
    padding: 0.5rem;
  }

  .introContent {
    font-size: 2rem;
  }
}

@media screen and (max-width: 600px) {
  #intro {
    padding: 0.5rem;
  }

  .introContent {
    font-size: 10vw;
    margin-top: 3rem;
  }
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

@keyframes slide-in-fade-in {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
