#contactPage {
    min-height: calc(100vh - 10rem);
    width: 100vw;
    max-width: 90rem;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 3rem;
    
}

.contactPageTitle {
    font-size: 3rem;
    margin-bottom: 1rem;
}

.clientDesc {
    font-weight: 300;
    font-size: 1rem;
}

.contactDesc {
    padding: 1rem;
    font-size: medium;
    font-weight: 300;
}

.contactForm {
    margin: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90vw;
    max-width: 60rem;
}

.name, .email, .msg {
    font-size: medium;
    width: 100%;
    max-width: 40rem;
    margin: 0.5rem;
    padding: 0.5rem 1rem;
    color: white;
    border: none;
    border-radius: 0.5rem;
    background: rgb(29, 18, 18);
}

.submitBtn {
    background: white;
    border: none;
    border-radius: 0.5rem;
    margin: 2rem;
    padding: 0.75rem 3.5rem;
    cursor: pointer;
    color: black;
}

#overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.722);
    z-index: 999;
}

.submission-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.submission-popup p {
    margin: 0;
    font-size: 18px;
    color: #333;
    text-align: center;
}

.submission-popup h2 {
    color: #333;
    text-align: center;
}

@media screen and (max-width: 600px) {
    .submission-popup {
        width: 400px;
    }
    #contactPage {
        min-height: calc(100vh - 16rem);
    }
}