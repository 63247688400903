#feedbackPage{
    position: relative;
    min-height: calc(100vh - 20rem);
    margin: 0 auto;
    text-align: center;
    justify-content: center;
    max-width: 100%;
    margin-bottom: 3rem;
    background-color: #282c34;
    padding: 2rem;
}

.feedbackDesc {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: bold;
}

.feedbackPageTitle {
    text-align: center;
    font-size: 33px;
}
.arrow-right{
    font-size: 4rem;
}
.students-feedback{
    font-size: 1.8rem;
}